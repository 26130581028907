import React, {useState} from "react";
import UserImg from "../../images/user.png"
import Email from "../../images/sms.png"
import ContactUsButton from "../AboutUs/ContactUs_button";
import SupportFormSkeleton from "../SkeletonLoadingComponent/AboutSupportSkeleton/SupportForm";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";


export default function SupportUsForm({loading}){
    const apikey = envVariables.APIKEY;


    const [data, setData] = useState(
        //this is to retrieve the inputted data from the browser local storage
        () => JSON.parse(localStorage.getItem("SupportData")) || { email : "", message: "", name : "" }
    )
    const [PopUp, setPopUp] = useState(null);

    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);


    React.useEffect(
        function (){
            return(
                //creating a form of localstorage for the app on the browser
                localStorage.setItem("SupportData", JSON.stringify(data))
                //clean up function
            )
        },
        [data] // will run the function if anything in notes arr changes
    )

    function handleChange(event) {

        const {name, value} = event.target;
        // setting your new state
        setData(prevState => (
            {
                ...prevState,
                [name]: value
            }
        ))

    }

    const handleSubmit = async (e) => {
        e.preventDefault() //to remove the url log
        const form_data = {
            'Message': data,
        };

        setPopUp('Loading..')



        try {
            const formData = new FormData();
            formData.append('jarom', 'jaromtravels');
            formData.append('jarom_subject', 'Support & Contact Us');
            formData.append('jarom_message', JSON.stringify(form_data));


            const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.status === 201) {
                setPopUp('submitted')
                // console.log(response);
                // setTimeout(setPopUp(false), 20000)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setPopUp('Failed')
        }
    }

    return(
        <div className='formData'>
            <div className="sendUsMessageText">Send us a message and get a reply ASAP.</div>
            <form className="textInputField" onSubmit={handleSubmit}>

                {loading ?
                    <SupportFormSkeleton/>
                    :
                    <div className="iconInput">
                        <span className="icon"><img src={UserImg} alt="user"/></span>

                        <input
                            value={data.fullName}
                            onChange={handleChange}
                            className="FullNameInput cruise"
                            name="name" type="Text" placeholder="Full Name"
                            required={true}
                        />

                    </div>
                }


                {loading ?
                    <SupportFormSkeleton/>
                    :
                    <div className="iconInput">
                        <span className="icon"><img src={Email} alt="email"/></span>

                        <input
                            value={data.Email}
                            onChange={handleChange}
                            className="Email cruise"
                            name="email" type="email" placeholder="Email"
                            required={true}
                        />

                    </div>
                }

                {loading ?
                    <SupportFormSkeleton textarea={true}/>
                    :
                    <div>
                         <textarea
                             name="message" rows={4} cols={40}
                             value={data.Message}
                             onChange={handleChange}
                             className="textareaInput dodo"
                             placeholder="Type a message..."
                             required
                         />
                        {errorMessage && <div className="messageSupp">{errorMessage}</div>}
                    </div>
                }




                <ContactUsButton
                    className='SupportButton'
                    btn_Name={PopUp? PopUp : "Send Message"}
                    type={"submit"}
                    isLoading={isLoading}
                />

            </form>
        </div>
    )
}