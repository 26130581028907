import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import VisaDoubleInputField from "../Visa/visaDoubleInput";
import TitleDropdown from "../Visa/titleDropdown";
import envVariables from "../../dev";
import axiosWithAuth from "../Axios/axiosWithAuth";


export default function AdmissionInfo (props){
    const gender = ['Male', 'Female', 'Others'];

    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('Submmit Form');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            const AdmissionProcessing ={
                'Title': localStorage.getItem('FirstNameTitle')  ?  localStorage.getItem('FirstNameTitle'): '',
                'First_Name': localStorage.getItem('AdmissionFirstnameOS')  ?  localStorage.getItem('AdmissionFirstnameOS'): '',
                'Last_Name': localStorage.getItem('AdmissionSurnameOS')  ?  localStorage.getItem('AdmissionSurnameOS'): '',
                'Email': localStorage.getItem('AdmissionEmailOS')  ?  localStorage.getItem('AdmissionEmailOS'): '',
                'Phone_number_code': localStorage.getItem('AdmissionPhoneNumberCodeOS')  ?  localStorage.getItem('AdmissionPhoneNumberCodeOS'): '',
                'Phone_number': localStorage.getItem('AdmissionPhoneNumberOS')  ?  localStorage.getItem('AdmissionPhoneNumberOS'): '',
                'Home_address': localStorage.getItem('AdmissionHomeAddressOS')  ?  localStorage.getItem('AdmissionHomeAddressOS'): '',
                'Gender': localStorage.getItem('AdmissionGenderOS')  ?  localStorage.getItem('AdmissionGenderOS'): '',

                'Bachelor Degree':{
                    'School Name':localStorage.getItem('AdmissionSchoolNameBAOS')  ?  localStorage.getItem('AdmissionSchoolNameBAOS'): '',
                    'School Address':localStorage.getItem('AdmissionSchoolAddressBAOS')  ?  localStorage.getItem('AdmissionSchoolAddressBAOS'): '',
                    'School Starting Date':localStorage.getItem('AdmissionSchoolStartingDateBAOS')  ?  localStorage.getItem('AdmissionSchoolStartingDateBAOS'): '',
                    'School Finishing Date':localStorage.getItem('AdmissionSchoolFinishingDateBAOS')  ?  localStorage.getItem('AdmissionSchoolFinishingDateBAOS'): '',
                    'Grade':localStorage.getItem('AdmissionGradeBAOS')  ?  localStorage.getItem('AdmissionGradeBAOS'): '',
                },

                ' Secondary School':{
                    'School Name':localStorage.getItem('AdmissionSchoolNameSSOS')  ?  localStorage.getItem('AdmissionSchoolNameSSOS'): '',
                    'School Address':localStorage.getItem('AdmissionSchoolAddressSSOS')  ?  localStorage.getItem('AdmissionSchoolAddressSSOS'): '',
                    'School Starting Date':localStorage.getItem('AdmissionSchoolStartingDateSSOS')  ?  localStorage.getItem('AdmissionSchoolStartingDateSSOS'): '',
                    'School Finishing Date':localStorage.getItem('AdmissionSchoolFinishingDateSSOS')  ?  localStorage.getItem('AdmissionSchoolFinishingDateSSOS'): '',
                    'Grade':localStorage.getItem('AdmissionGradeSSOS')  ?  localStorage.getItem('AdmissionGradeSSOS'): '',
                },

                'Primary School':{
                    'School Name':localStorage.getItem('AdmissionSchoolNamePSOS')  ?  localStorage.getItem('AdmissionSchoolNamePSOS'): '',
                    'School Address':localStorage.getItem('AdmissionSchoolAddressPSOS')  ?  localStorage.getItem('AdmissionSchoolAddressPSOS'): '',
                    'School Starting Date':localStorage.getItem('AdmissionSchoolStartingDatePSOS')  ?  localStorage.getItem('AdmissionSchoolStartingDatePSOS'): '',
                    'School Finishing Date':localStorage.getItem('AdmissionSchoolFinishingDatePSOS')  ?  localStorage.getItem('AdmissionSchoolFinishingDatePSOS'): '',
                    'Grade':localStorage.getItem('AdmissionGradePSOS')  ?  localStorage.getItem('AdmissionGradePSOS'): '',
                },

                'Emergency Information':{
                    'Title': localStorage.getItem('FirstNameTitle')  ?  localStorage.getItem('FirstNameTitle'): '',
                    'First_Name': localStorage.getItem('AdmissionFirstnameEIOS')  ?  localStorage.getItem('AdmissionFirstnameEIOS'): '',
                    'Last_Name': localStorage.getItem('AdmissionSurnameEIOS')  ?  localStorage.getItem('AdmissionSurnameEIOS'): '',
                    'Email': localStorage.getItem('AdmissionEmailEIOS')  ?  localStorage.getItem('AdmissionEmailEIOS'): '',
                    'Phone_number_code': localStorage.getItem('AdmissionPhoneNumberCodeEIOS')  ?  localStorage.getItem('AdmissionPhoneNumberCodeEIOS'): '',
                    'Phone_number': localStorage.getItem('AdmissionPhoneNumberEIOS')  ?  localStorage.getItem('AdmissionPhoneNumberEIOS'): '',
                    'Home_address': localStorage.getItem('AdmissionHomeAddressEIOS')  ?  localStorage.getItem('AdmissionHomeAddressEIOS'): '',
                    'Relatioship_to_you': localStorage.getItem('AdmissionRelationshipToYouEIOS')  ?  localStorage.getItem('AdmissionRelationshipToYouEIOS'): '',
                },

            }


            const form_data = {
                'Admission Processing': AdmissionProcessing,
            };


            setPopUp('Loading.....')


            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'Admission Processing(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                if (response.status === 201) {
                    setPopUp('submitted')
                    // console.log(response);
                    // setTimeout(setPopUp(false), 20000)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed')
            }
        }

    };

    return(
        <form className='blurred-div' ref={props.close} onSubmit={(e)=>handleSubmit(e)}>
            <div className="blueHeader">
                Personal Information
            </div>
            <div className="IELTS1">
                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='AdmissionSurnameOS'
                                placeholder='Surname as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='First name' star='*' type='text'
                                localStorageName='AdmissionFirstnameOS'
                                placeholder='Other Name as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Email Adderess' star='*' type='email'
                                localStorageName='AdmissionEmailOS'
                                placeholder='xyz@gmailcom' required={true}>

                </VisaInputField>

                <div className="IELTSInner">
                    <VisaDoubleInputField localStorageName='AdmissionPhoneNumberOS'
                                          localTitleStorage='AdmissionPhoneNumberCodeOS'
                                          labelName='Phone Number' star='*'
                                          firstPlaceHolder='+234' type='tel'
                                          placeholder='Phone Number' value_i='+234' required={true}>
                    </VisaDoubleInputField>

                    <div className='visa_inputField ' id='inputTop'>
                        <label>
                            Gender<span>*</span>
                        </label>
                        <TitleDropdown name='Gender' localStorageName='AdmissionGenderOS' mwidth='130px'
                                       mxfont='14px' mxwidth='120px' mfont='10px'
                                       options={gender} title="Select"
                                       className='visa_input'/>
                    </div>
                </div>

                <VisaInputField labelName='Home Address' star='*' type='text'
                                localStorageName='AdmissionHomeAddressOS'
                                placeholder='Home Address' required={true}>

                </VisaInputField>
            </div>


            <div className="blueHeader">
                Academic Information
            </div>
            <div className="IELTS1 IELTS2">
                <div className="subBlueHeader">
                    Bachelor Degree
                </div>

                <VisaInputField labelName='School Name' star='*' type='text'
                                localStorageName='AdmissionSchoolNameBAOS'
                                placeholder='School Name' required={true}>

                </VisaInputField>
                <VisaInputField labelName='School Address' star='*' type='text'
                                localStorageName='AdmissionSchoolAddressBAOS'
                                placeholder='School Address' required={true}>

                </VisaInputField>
                <div className="IELTSInner2">
                    <VisaInputField labelName='School Starting Date' star='*' type='date'
                                    localStorageName='AdmissionSchoolStartingDateBAOS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>
                    <VisaInputField labelName='School Finishing Date' star='*' type='date'
                                    localStorageName='AdmissionSchoolFinishingDateBAOS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                </div>

                <VisaInputField labelName='Grade' star='*' type='text'
                                localStorageName='AdmissionGradeBAOS'
                                placeholder='Grade' required={true}>

                </VisaInputField>

            </div>

            <div className="IELTS1 IELTS2">
                <div className="subBlueHeader">
                    Secondary School
                </div>

                <VisaInputField labelName='School Name' star='*' type='text'
                                localStorageName='AdmissionSchoolNameSSOS'
                                placeholder='School Name' required={true}>

                </VisaInputField>
                <VisaInputField labelName='School Address' star='*' type='text'
                                localStorageName='AdmissionSchoolAddressSSOS'
                                placeholder='School Address' required={true}>

                </VisaInputField>
                <div className="IELTSInner2">
                    <VisaInputField labelName='School Starting Date' star='*' type='date'
                                    localStorageName='AdmissionSchoolStartingDateSSOS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>
                    <VisaInputField labelName='School Finishing Date' star='*' type='date'
                                    localStorageName='AdmissionSchoolFinishingDateSSOS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                </div>

                <VisaInputField labelName='Grade' star='*' type='text'
                                localStorageName='AdmissionGradeSSOS'
                                placeholder='Grade' required={true}>

                </VisaInputField>

            </div>

            <div className="IELTS1 IELTS2">
                <div className="subBlueHeader">
                    Primary School
                </div>

                <VisaInputField labelName='School Name' star='*' type='text'
                                localStorageName='AdmissionSchoolNamePSOS'
                                placeholder='School Name' required={true}>

                </VisaInputField>
                <VisaInputField labelName='School Address' star='*' type='text'
                                localStorageName='AdmissionSchoolAddressPSOS'
                                placeholder='School Address' required={true}>

                </VisaInputField>
                <div className="IELTSInner2">
                    <VisaInputField labelName='School Starting Date' star='*' type='date'
                                    localStorageName='AdmissionSchoolStartingDatePSOS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>
                    <VisaInputField labelName='School Finishing Date' star='*' type='date'
                                    localStorageName='AdmissionSchoolFinishingDatePSOS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                </div>

                <VisaInputField labelName='Grade' star='*' type='text'
                                localStorageName='AdmissionGradeBAOS'
                                placeholder='Grade' required={true}>

                </VisaInputField>

            </div>

            <div className="blueHeader">
                Emergency Information
            </div>

            <div className="IELTS1">
                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='AdmissionSurnameEIOS'
                                placeholder='Surname as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='First name' star='*' type='text'
                                localStorageName='AdmissionFirstnameEIOS'
                                placeholder='First Name as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Email Address' star='*' type='email'
                                localStorageName='AdmissionEmailEIOS'
                                placeholder='xyz@gmailcom' required={true}>

                </VisaInputField>
                <VisaDoubleInputField localStorageName='AdmissionPhoneNumberEIOS'
                                      localTitleStorage='AdmissionPhoneNumberCodeEIOS'
                                      labelName='Phone Number' star='*'
                                      firstPlaceHolder='+234' type='tel'
                                      placeholder='Phone Number' value_i='+234' required={true}>
                </VisaDoubleInputField>
                <VisaInputField labelName='Relationship to you' star='*' type='text'
                                localStorageName='AdmissionRelationshipToYouEIOS'
                                placeholder='Relationship to you' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Home Address' star='*' type='text'
                                localStorageName='AdmissionHomeAddressEIOS'
                                placeholder='Home Address' required={true}>

                </VisaInputField>
            </div>

            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'  onClick={handleSubmit} type='submit'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveStateadmissionInfo(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>
    )
}