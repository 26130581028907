import React, {useState} from "react";
import "./footer.css"
import ContactUsButton from "../AboutUs/ContactUs_button";
import {LazyLoadImage} from "react-lazy-load-image-component";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";
import {Link} from "react-router-dom";


export default function MyFooter() {
    const [subscriberEmail, setSubscriberEmail] = useState('')
    const [errmsg, setErrmsg] = useState(null)
    const [payLoading1, setPayLoading1] = useState(false)

    function handleChange(event) {
        setSubscriberEmail(event.target.value)
    }

    const apikey = envVariables.APIKEY;

    const handleSubmit = async (event) =>{
        event.preventDefault() //to remove the url log
        setPayLoading1(true)


        const email = subscriberEmail;

        try {
            const requestData = {
               email
            };
            const response = await axiosWithAuth(apikey).post('/user/subscribe-email/', requestData);
            if (response.status === 200 || response.status === 201) {
                // setPayLoading1(false)
                // setErrImg('https://res.cloudinary.com/do5wu6ikf/image/upload/v1702572525/starpenzu/Check_g_otgwce.svg');
                setErrmsg('successful')
                // tokenizeApi()
                console.error('Success');
                setPayLoading1(false)

            }
        } catch (error) {
            // setErrImg('https://res.cloudinary.com/do5wu6ikf/image/upload/v1702572525/starpenzu/Check_r_naosbf.svg')
            setErrmsg('Error')
            setPayLoading1(false);
            console.error('Error fetching data:', error);
        }
    }

    setInterval(() => setErrmsg(null), 2000)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Adds a smooth scrolling effect
        });
    };

    return (
        <footer>
            <div className='sFooter'>

                <div className="subFooter">

                    <div className="subFooterText">
                        <div className='newsletterHeader'> Subscribe to Newsletter</div>
                        <p> Sign up to receive exclusive discount and special offers</p>
                    </div>

                    <form onSubmit={handleSubmit} className='subForm'>
                        <div>
                            <input
                                value={subscriberEmail}
                                onChange={handleChange}
                                className="subInput"
                                name="Email" type="email" placeholder="Email"
                            /></div>

                        <ContactUsButton
                            //onClick={handleSubmit}
                            className='subButton'
                            btn_Name={payLoading1 ? 'Loading..':"Subscribe Now"}
                            type={"submit"}
                            errmsg={errmsg}
                        />
                    </form>

                </div>
                <div className="footerFix">
                    <div>
                        <div className="footerMain">

                            <div className="leftSeg">
                                <div className="footerLogo">
                                    <LazyLoadImage effect="blur" className='logo'
                                                   src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1683163940/nakedjarome_1_xfopye.svg"/>
                                </div>

                                <div className="footerText">
                                    Jarom Travels Agency Limited has grown into a full-fledged travel company providing
                                    top-notch services such as Corporate,
                                    Business, & Group Travel, Flight Ticket, Cruises, Visa applications and Processes, Studies
                                    Abroad, Airport Pickups, Hotel
                                    reservations, Special Event Planning, All-Inclusive Resort Stays, Destination Weddings &
                                    Honeymoons, Vacations, and Tours
                                    etcetera
                                </div>

                                <div className="connectFooter">
                                    Connect with us:
                                </div>

                                <div className="footerSocials">
                                    <img src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1681170452/Vector_jokqvs.svg" alt='Twitter'/>
                                    <img
                                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1681170452/Vector_1_ttb2wa.svg' alt='Instagram'/>
                                    <img
                                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1681170452/Group_1_vrhr74.svg' alt='Facebook'/>
                                    <img src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1681170452/Group_azetxw.svg' alt='Linkedin'/>
                                </div>
                            </div>

                            <div className="middleSeg">

                                <div className="services">
                                    <div className='mimick_h1'>Services</div>

                                    <ul>
                                        <Link style={{color: 'white'}} onClick={scrollToTop} to='/visa'>
                                            <li className='hover'>Visa Application</li>
                                        </Link>
                                        <li className='hover'>Flight Ticket</li>

                                        <Link style={{color: 'white'}} onClick={scrollToTop} to='/ourservices'>
                                            <li className='hover'>Study Abroad</li>
                                        </Link>

                                        <Link style={{color: 'white'}} onClick={scrollToTop} to='/ourservices'><li className='hover'>Tours and Vacation</li></Link>

                                        <Link style={{color: 'white'}} onClick={scrollToTop} to='/ourservices'> <li className='hover'>Birth Services</li></Link>

                                        <Link style={{color: 'white'}} onClick={scrollToTop} to='/ourservices'> <li className='hover'>GRE/IELTS/TOEFL/
                                            PTE Exams
                                        </li></Link>

                                        <Link style={{color: 'white'}} onClick={scrollToTop} to='/ourservices'>
                                            <li className='hover'>Permanent Residency</li>
                                        </Link>




                                        <li>Honeymoon Packages</li>
                                        <li>Airport Pickup</li>


                                    </ul>

                                </div>


                                <div className="aboutUsFooter">
                                    <div className='mimick_h1'>About Us</div>

                                    <ul>
                                        <Link style={{color: 'white'}} onClick={scrollToTop} to='/AboutUs'>
                                            <li className='hover'>About</li>
                                        </Link>
                                        <li>Terms of service</li>
                                        <li>Privacy Policy</li>
                                        <li>Cookies Policy</li>

                                        <Link style={{color: 'white'}} onClick={scrollToTop}  to='/AboutUs'>
                                            <li className='hover'>FAQs</li>
                                        </Link>
                                    </ul>

                                </div>

                                <div className="contactUsFooter">
                                    {/*<Link to='/Support'>*/}
                                        <div className='mimick_h1'>Contact Us</div>

                                    {/*</Link>*/}

                                    <ul>
                                        <li><div className='mimick_h2'>Tel:</div></li>
                                        <li><p>+234 (0)90 9562 8888</p></li>
                                        <li><div className='mimick_h2'>Email:</div></li>
                                        <li><p>contact@Jaromtravels.com</p></li>
                                        <li><div className='mimick_h2'>Office Address:</div></li>
                                        <li><p>Novare Shared office, Zone 5 Dalaba St. Wuse.</p></li>
                                    </ul>

                                </div>
                            </div>

                            <div className="rightSeg">
                                <div className="mission">
                                    <div className='mimick_h1'>Mission</div>
                                    <p>
                                        Our mission is to become the first-choice travel Agency for institutions and clients
                                        around
                                        the world, adding more valuable services to customers in the Travel industry
                                    </p>

                                </div>

                                <div className="vision">
                                    <div className='mimick_h1'>Vision</div>
                                    <p>
                                        Integrating travel agencies to NEXT LEVEL to achieve maximum growth and satisfy our
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyRight">
                    <p>&copy; Copyright 2024. All Rights Reserved</p>
                </div>

            </div>
        </footer>
    )
}