import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom'
import BlueBack from "../SignUp/BlueBack";
import './login.css';
import InputField from "../InputField/InputField";
import sma from "../../images/sms.png";
import axiosWithAuth from "../Axios/axiosWithAuth";
import lock from "../../images/lock.png";
import Spinner from "../Spinner/Spinner";
import envVariables from "../../dev";
import {Helmet} from "react-helmet-async";


export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [memory, setMemory] = useState({'keepmelogged':false});
    const [isLoading, setIsLoading] = useState(false);




    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const apikey = envVariables.APIKEY;
            const response = await axiosWithAuth(apikey).post('/user/token/', {
                email,
                password,
            });

            if (response.data.token) {
                const { token } = response.data;

                const expiryHours = memory ? memory.keepmelogged ===  true ? 12 : 1 : undefined;
                const expiryDate = new Date();
                expiryDate.setTime(expiryDate.getTime() + (expiryHours * 60 * 60 * 1000));
                const cookieOptions = {
                    expires: expiryDate,
                    secure: true, // Ensures the cookie is sent only over HTTPS
                    // httpOnly: true, // Prevents client-side scripts from accessing the cookie
                    sameSite: 'strict', // Mitigates CSRF attacks
                    // Optionally, you can set the "Path" attribute to restrict the cookie to specific paths on your domain
                    // path: '/',
                    // Optionally, you can set the "Domain" attribute to restrict the cookie to a specific subdomain
                    // domain: '.jaromtravels.com'
                };
                const cookieString = `AUTH-Token=${encodeURIComponent(token)};${Object.entries(cookieOptions).map(([key, value]) => `${key}=${value}`).join(';')}`;
                document.cookie = cookieString;

                navigate('/');
                // window.location.reload();
            } else {
                throw new Error('Invalid credentials. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };


    function handleChange(event) {

        const {name, type, value, checked} = event.target;
        // setting your new state
        setMemory(prevState => (
            {
                ...prevState,
                [name]: type === "checkbox" ? checked : value
            }
        ))
    }

    return (
        <>
            <Helmet>
                <title>Login - Jarom Travel Agency</title>
                <meta name="description" content="Login to  your next adventure with Jarom travel agency. Join us for exclusive deals and personalized travel experiences." />
                <meta name="keywords" content="travel, sign in, Login, travel deals, vacation packages, Flight Tickets, Visa Processing, International Exams" />
                <link rel="canonical" href="https://jaromtravels.com/login" />
                <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
                </script>
            </Helmet>
            <div>
                <BlueBack containerClassName='loginBack'>
                    <img id='SignUpBack-cancelButton' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png' alt='cancelButton'/>
                    <h1 className='firstOne'>
                        Welcome to Jarom Travels.
                    </h1>
                    <div className='secOne' >Have access to prompt flight updates and<br/>special offers</div>
                    <button className='socialButtons'><img alt='googleLogo' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678228461/flat-color-icons_google_j3ofzp.png'/>Sign up with Google</button>
                    <button className='socialButtons'><img alt='faceBookLogo' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678228905/ci_apple_xdoib0.png'/>Sign up with FaceBook</button>
                    <div className='liner'><hr/><span className='or'>or</span><hr/></div>
                    <form onSubmit={handleSubmit}>
                        <InputField classNameSg='sg sgx' value={email} onChange={(event) => setEmail(event.target.value)} className="col-9 fi"
                                    name="Email" type="email" placeholder="Email" required iconSrc={sma}/>
                        <InputField classNameSg='sg' className="col-9 fi" name="Password" type="password"
                                    placeholder="Password" onChange={(event) => setPassword(event.target.value)} value={password}
                                    required={true} iconSrc={lock}/>
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        <div className='forget'>
                            <Link to="/password-reset-email">
                                Forget Password
                            </Link>
                        </div>

                        <label>
                            <input type="checkbox" name='keepmelogged' onChange={(event)=> handleChange(event)} className="custom-checkbox"/>
                            <span className="checkmark"></span>
                            <span className='logged'>Keep me logged in</span>
                        </label>
                        <button type='submit' disabled={isLoading} className='stepButton stepButtonMargin'>
                            {isLoading ? <Spinner /> : 'Login'}
                        </button>
                    </form>

                    <div className='blueBack-fort toper'>Don't have an account? <span><Link to ="/signup">Register</Link>
                </span>. </div>
                </BlueBack>
            </div>
        </>
    );
}

