import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import RadioInput from "../Visa/radioDoubleOption";
import envVariables from "../../dev";
import axiosWithAuth from "../Axios/axiosWithAuth";


export default function BirthServices (props){

    const [Haveyougivenbirthbefore, Sethaveyougivenbirthbefore] = useState('');
    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('Submmit Form');
    const handleSubmit = async (e) => {

        e.preventDefault();
        if (e.target.checkValidity()) {

            const BirthService ={
                'First_Name': localStorage.getItem('BirthFirstNameOS')  ?  localStorage.getItem('BirthFirstNameOS'): '',
                'Middle_Name': localStorage.getItem('BirthMiddleNameOS')  ?  localStorage.getItem('BirthMiddleNameOS'): '',
                'Last_Name': localStorage.getItem('BirthSurnameOS')  ?  localStorage.getItem('BirthSurnameOS'): '',
                'middle_Name': localStorage.getItem('middleName')  ?  localStorage.getItem('middleName'): '',
                'How long gone is your child?': localStorage.getItem('BirthCurrentGestationalageOS')  ?  localStorage.getItem('BirthCurrentGestationalageOS'): '',
                'How many baby(ies) are you giving birth to?': localStorage.getItem('BirthNoBabiesOS')  ?  localStorage.getItem('BirthNoBabiesOS'): '',
                'When is your EDD (Estimated Due Date)?': localStorage.getItem('BirthEDD_OS')  ?  localStorage.getItem('BirthEDD_OS'): '',
                'When is your traveling date?': localStorage.getItem('BirthTravellingDateOS')  ?  localStorage.getItem('BirthTravellingDateOS'): '',
                "Have you given birth before?": Haveyougivenbirthbefore === 'Yes'? {'Yes': Haveyougivenbirthbefore,'Were there any complications?(Give details)': localStorage.getItem('were_there_any_complications')  ?  localStorage.getItem('were_there_any_complications'): '',} :
                    { 'No':localStorage.getItem('Have_you_given_birth_before')  ?  localStorage.getItem('Have_you_given_birth_before') : ''},
                'Is this your first pregnancy?': localStorage.getItem('Is_this_your_first_pregnancy')  ?  localStorage.getItem('Is_this_your_first_pregnancy'): '',
                'When do you want to start the application?': localStorage.getItem('BirthStartProcssingDateOS')  ?  localStorage.getItem('BirthStartProcssingDateOS'): '',
            }

            const form_data = {
                'Birth Service': BirthService,
            };
            setPopUp('Loading...')

            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'Birth Service(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.status === 201) {
                    setPopUp('Submitted')
                    // console.log(response);
                    // setTimeout(setPopUp(false), 20000)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed')
            }
        }

    };
    const radioUpdate__ = () => {
        const storedValue = localStorage.getItem('Have_you_given_birth_before');
        if (storedValue) {
            Sethaveyougivenbirthbefore(storedValue);
        }
    };
    return(
        <form ref={props.close} onSubmit={(e)=>handleSubmit(e)} className='blurred-div'>
            <div className="blueHeader">
                Registration Form
            </div>
            <div className="IELTS1">


                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='BirthSurnameOS'
                                placeholder='Surname as on intl. passport' required={true}>
                </VisaInputField>

                <VisaInputField labelName='Middle name' star='*' type='text'
                                localStorageName='BirthMiddleNameOS'
                                placeholder='Middle name as on intl. passport' required={true}>

                </VisaInputField>

                <VisaInputField labelName='First name' star='*' type='text'
                                localStorageName='BirthFirstNameOS'
                                placeholder='Last name as on intl. passport' required={true}>

                </VisaInputField>

                <VisaInputField labelName='How long gone is your child?' star='*' type='text'
                                localStorageName='BirthCurrentGestationalageOS'
                                placeholder='How long gone is your child' required={true}>

                </VisaInputField>

                <VisaInputField labelName='How many baby(ies) are you giving birth to?' star='*' type='text'
                                localStorageName='BirthNoBabiesOS'
                                placeholder='How many babies are you giving birth to?' required={true}>

                </VisaInputField>

                <div className="IELTSInner2">
                    <VisaInputField labelName='When is your EDD (Estimated Due Date)?' star='*' type='date'
                                    localStorageName='BirthEDD_OS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='When is your traveling date?' star='*' type='date'
                                    localStorageName='BirthTravellingDateOS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                </div>

                <RadioInput onClick={radioUpdate__} text="Have you given birth before?"
                            localStorageName="Have_you_given_birth_before"/>
                {Haveyougivenbirthbefore === 'Yes' && (
                    <div>
                        <VisaInputField id='visa-Input'
                                        localStorageName='were_there_any_complications'
                                        labelName='Were there any complications?(Give details)'
                                        star='*' type='text' placeholder='Were there any complications?'
                                        required={true}>
                        </VisaInputField>
                    </div>
                )}

                <RadioInput
                    text="Is this your first pregnancy?"
                    localStorageName="Is_this_your_first_pregnancy"
                />


                <div className="IELTSInner2">
                    <VisaInputField labelName='When do you want to start the application? (Processing time to get hospital and doctor is 3 to 4 weeks. It is advisable to start as early as possible.)' star='*' type='date'
                                    localStorageName='BirthStartProcssingDateOS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                </div>
            </div>


            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next' type='submit'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveStateIELTS(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>
    )
}