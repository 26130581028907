import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import TitleDropdown from "../Visa/titleDropdown";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";
import RadioInput from "../Visa/radioDoubleOption";


const HoneyMoonForm = (props) => {
    const[value, setValue] = useState('');
    const[underAge, setUnderAge] = useState('');
    const[passport, SetPassport] = useState('');


    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('');
    const referrers = [
        "A friend",
        "Family member",
        "Colleague",
        "Travel Agent",
        "Previous Client",
        "Social Media Influencer",
        "Online Review",
        "Hotel or Resort Recommendation",
        "Event or Travel Fair",
        "Business Partner",
        "Other",
    ];

    const heardAboutUs = [
        "Instagram",
        "Facebook",
        "Google Search",
        "Travel Blog",
        "YouTube",
        "Referral from a Friend",
        "Advertisement (e.g., Magazine, TV, Online Ad)",
        "Twitter/X",
        "LinkedIn",
        "Pinterest",
        "Travel Fair or Expo",
        "Email Newsletter",
        "TikTok",
        "Other Social Media",
    ];



    const roomCabinTypes = [
        "Overwater Bungalow with Private Pool (e.g., Maldives, Bora Bora)",
        "Beachfront Suite (e.g., Caribbean, Seychelles)",
        "Mountain View Cabin (e.g., Smoky Mountains, Swiss Alps)",
        "Ocean View Room (e.g., Miami Beach, Hawaii)",
        "Penthouse Suite (e.g., New York City, Dubai)",
        "Garden Villa (e.g., Bali, Thailand)",
        "Luxury Tent (e.g., Safari in Kenya, Glamping in Montana)",
        "Treehouse Room (e.g., Costa Rica, Thailand)",
        "Family Suite (e.g., Disney Resorts, Atlantis)",
        "Standard Room (e.g., City Hotels, Budget Stays)",
    ];

    const resortTypes = [
        "All-Inclusive Resort (e.g., Sandals, Club Med)",
        "Overwater Villas (e.g., Maldives, Bora Bora)",
        "Beachfront Resort (e.g., Miami Beach, Cancun)",
        "Mountain Resort (e.g., Aspen, Swiss Alps)",
        "Luxury Resort (e.g., Four Seasons, Ritz-Carlton)",
        "Eco-Resort (e.g., Costa Rica Rainforest, Bali Eco Retreats)",
        "Boutique Resort (e.g., Santorini, Amalfi Coast)",
        "Adults-Only Resort (e.g., Secrets Resorts, Excellence Resorts)",
        "Family Resort (e.g., Disney Resorts, Atlantis)",
        "Spa Resort (e.g., Canyon Ranch, Six Senses Spa)",
    ];






    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {


            const HoneyMoon ={
                'Surname': localStorage.getItem('Surname_HM')  ?  localStorage.getItem('Surname_HM'): '',
                'Full_Name': localStorage.getItem('Full_Name_HM')  ?  localStorage.getItem('Full_Name_HM'): '',
                'Email': localStorage.getItem('Email_HM')  ?  localStorage.getItem('Email_HM'): '',
                'Phone_number': localStorage.getItem('Phone_number_HM')  ?  localStorage.getItem('Phone_number_HM'): '',
                'number_of_Adult_Guests': localStorage.getItem('number_of_Adult_Guests_HM')  ?  localStorage.getItem('number_of_Adult_Guests_HM'): '',
                'Do_all_travelers_have_a_passport': localStorage.getItem('AllPassport_HM')  ?  localStorage.getItem('AllPassport_HM'): '',
                'Child_Age': localStorage.getItem('underAge_HM')  ?  localStorage.getItem('underAge_HM'): '',
                'Departing_City': localStorage.getItem('Departing_City_HM')  ?  localStorage.getItem('Departing_City_HM'): '',
                'Destination': localStorage.getItem('Destination_HM')  ?  localStorage.getItem('Destination_HM'): '',
                'Desired_Departure_Date': localStorage.getItem('Depature_Date_HM')  ?  localStorage.getItem('Depature_Date_HM'): '',
                'Desired_Return_Date': localStorage.getItem('Return_Date_HM')  ?  localStorage.getItem('Return_Date_HM'): '',
                'Are_Dates_Flexible': localStorage.getItem('Are_date_flexibe_HM')  ?  localStorage.getItem('Are_date_flexibe_HM'): '',
                'Number_of_Nights': localStorage.getItem('number_of_Night_HM')  ?  localStorage.getItem('number_of_Night_HM'): '',
                'Estimated_Budget': localStorage.getItem('Budget_HM')  ?  localStorage.getItem('Budget_HM'): '',
                'Resort_Type': localStorage.getItem('resortTypes_HM')  ?  localStorage.getItem('resortTypes_HM'): '',
                'Room_Cabin_Type': localStorage.getItem('roomCabinTypes_HM')  ?  localStorage.getItem('roomCabinTypes_HM'): '',
                'Where_did_you_first_hear_about_us': localStorage.getItem('heardAboutUs_HM')  ?  localStorage.getItem('heardAboutUs_HM'): '',
                'Who_referred_you': localStorage.getItem('referrers_HM')  ?  localStorage.getItem('referrers_HM'): '',
                'Important_Info': localStorage.getItem('importantInfo_HM')  ?  localStorage.getItem('importantInfo_HM'): '',
                'Other_Info': localStorage.getItem('OtherInfo_HM')  ?  localStorage.getItem('OtherInfo_HM'): '',
            }


            const form_data = {
                'HoneyMoon': HoneyMoon,
            };

            setPopUp('Loading.....')

            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'Permanent Residency in Canada(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.status === 201) {
                    setPopUp('submitted')
                    // console.log(response);
                    // setTimeout(setPopUp(false), 20000)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed')
            }

        }


    };

    const radioUpdate___ = () => {
        const storedValue = localStorage.getItem('Are_date_flexibe_HM');
        if (storedValue) {
            setValue(storedValue);
        }
    };

    const radioUpdate__ = () => {
        const storedValue = localStorage.getItem('underAge_HM');
        if (storedValue) {
            setUnderAge(storedValue);
        }
    };

    const radioUpdate_ = () => {
        const storedValue = localStorage.getItem('AllPassport_HM');
        if (storedValue) {
            SetPassport(storedValue);
        }
    };

    return (
        <form ref={props.close}  onSubmit={(e)=>handleSubmit(e)} className='blurred-div'>
            <div className="blueHeader">
                Contact Information
            </div>
            <div className="IELTS1">

                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='Surname_HM'
                                placeholder='Surname as on intl. passport' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Full Name' star='*' type='text'
                                localStorageName='FullName_HM'
                                placeholder='Full name as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Email' star='*' type='email'
                                localStorageName='Email_HM'
                                placeholder='jarom@gmail.com' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Phone Number' star='*' type='number'
                                localStorageName='Phone_number_HM'
                                placeholder='Phone Number' required={true}>

                </VisaInputField>


                <VisaInputField labelName='Number of Adult Guests' star='*' type='text'
                                localStorageName='number_of_Adult_Guests_HM'
                                placeholder='2' required={true}>

                </VisaInputField>


                <div className="IELTSInner2">
                    <RadioInput onClick={radioUpdate_} text="Do all travelers have a passport?"
                                localStorageName="AllPassport_HM"/>

                    <RadioInput onClick={radioUpdate__} text="Child Age(s) (Under 18):"
                                localStorageName="underAge_HM"/>
                </div>
            </div>

            <div className="blueHeader">
                Trip Information
            </div>
            <div className="IELTS1">
                <div className="IELTSInner2">

                    <VisaInputField labelName='Departing City' star='*' type='text'
                                    localStorageName='Departing_City_HM'
                                    placeholder='New York (JFK)' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Destination' star='*' type='text'
                                    localStorageName='Destination_HM'
                                    placeholder='Maldives' required={true}>

                    </VisaInputField>
                </div>

                <div className="IELTSInner2">
                    <VisaInputField labelName='Desired Departure Date' star='*' type='date'
                                    localStorageName='Depature_Date_HM'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Desired Return Date' star='*' type='date'
                                    localStorageName='Return_Date_HM'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>
                </div>


                <RadioInput onClick={radioUpdate___} text="Are Dates Flexible?"
                            localStorageName="Are_date_flexibe_HM"/>






                <div className="IELTSInner2">
                    <VisaInputField labelName='Number of Nights' star='*' type='text'
                                    localStorageName='number_of_Night_HM'
                                    placeholder='2' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Estimated Budget: ' star='*' type='text'
                                    localStorageName='Budget_HM'
                                    placeholder='$10,000 - $12,000' required={true}>

                    </VisaInputField>
                </div>



                <div className='visa_inputField ' id='inputTop'>
                    <label>
                        Resort Type<span>*</span>
                    </label>
                    <TitleDropdown name='Resort Type' localStorageName='resortTypes_HM' mwidth='130px'
                                   mxfont='14px' mxwidth='100%' mfont='10px'
                                   options={resortTypes} title="Select"
                                   className='visa_input'/>

                </div>


                <div className='visa_inputField ' id='inputTop'>
                        <label>
                            Room/Cabin Type<span>*</span>
                        </label>
                        <TitleDropdown name='Room/Cabin Type' localStorageName='roomCabinTypes_HM' mwidth='130px'
                                       mxfont='14px' mxwidth='100%' mfont='10px'
                                       options={roomCabinTypes} title="Select"
                                       className='visa_input'/>

                    </div>



                    <div className='visa_inputField ' id='inputTop'>
                        <label>
                            Where did you first hear about us?<span>*</span>
                        </label>
                        <TitleDropdown name='Preferred Aircraft' localStorageName='heardAboutUs_HM' mwidth='130px'
                                       mxfont='14px' mxwidth='100%' mfont='10px'
                                       options={heardAboutUs} title="Select"
                                       className='visa_input'/>

                    </div>

                    <div className='visa_inputField ' id='inputTop'>
                        <label>
                            Who referred you? <span>*</span>
                        </label>
                        <TitleDropdown name='Who referred you?' localStorageName='referrers_HM' mwidth='130px'
                                       mxfont='14px' mxwidth='100%' mfont='10px'
                                       options={referrers} title="Select"
                                       className='visa_input'/>

                    </div>


                <VisaInputField labelName='Are there any other important things to you that were not listed? (Optional)' star='*' type='text'
                                localStorageName='importantInfo_HM'
                                placeholder='A private beach dinner setup for the honeymoon'>

                </VisaInputField>


                <VisaInputField labelName='Is there any other information that you would like to provide that will help us better plan your trip? (Optional)' star='*' type='text'
                                localStorageName='OtherInfo_HM'
                                placeholder='Prefer a room with sunset views and a spa package included.' >

                </VisaInputField>







            </div>


            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveJetHireForm(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>

    );
};

export default HoneyMoonForm;