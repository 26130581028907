import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import TitleDropdown from "../Visa/titleDropdown";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../../src/dev";


const AirportPickupForm = (props) => {
    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('');






    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {


            const AirportPickup ={
                'Surname': localStorage.getItem('Surname_AP')  ?  localStorage.getItem('Surname_AP'): '',
                'Full_Name': localStorage.getItem('FullName_AP')  ?  localStorage.getItem('FullName_AP'): '',
                'Email': localStorage.getItem('Email_AP')  ?  localStorage.getItem('Email_AP'): '',
                'Phone_Number': localStorage.getItem('Phone_number_AP')  ?  localStorage.getItem('Phone_number_AP'): '',
                'Type_of_Trip': localStorage.getItem('Type_of_Trip_AP')  ?  localStorage.getItem('Type_of_Trip_AP'): '',
                'Name_of_Airport': localStorage.getItem('airPort_AP')  ?  localStorage.getItem('airPort_AP'): '',
                'Terminal_of_the_Airport': localStorage.getItem('Airport_terminal_AP')  ?  localStorage.getItem('Airport_terminal_AP'): '',
                'Pickup_Date': localStorage.getItem('Pickup_Date_AP')  ?  localStorage.getItem('Pickup_Date_AP'): '',
                'Pickup_Time': localStorage.getItem('PickupTime_AP')  ?  localStorage.getItem('PickupTime_AP'): '',
                'Airline': localStorage.getItem('AirLine_AP')  ?  localStorage.getItem('AirLine_AP'): '',
                'Flight_Number': localStorage.getItem('FLightNumber_AP')  ?  localStorage.getItem('FLightNumber_AP'): '',
                'Number_of_Luggage': localStorage.getItem('number_of_Luggage_AP')  ?  localStorage.getItem('number_of_Luggage_AP'): '',
                'Number_of_Passengers': localStorage.getItem('Passengers_AP')  ?  localStorage.getItem('Passengers_AP'): '',
                'Pickup_Location': localStorage.getItem('PickupLocation_AP')  ?  localStorage.getItem('PickupLocation_AP'): '',
                'Drop_Off_Location': localStorage.getItem('Drop_Off_Location_AP')  ?  localStorage.getItem('Drop_Off_Location_AP'): '',
                'Special_Request': localStorage.getItem('SpecialRequest_AP')  ?  localStorage.getItem('SpecialRequest_AP'): '',
            }


            const form_data = {
                'AirportPickup': AirportPickup,
            };

            setPopUp('Loading.....')

            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'Permanent Residency in Canada(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.status === 201) {
                    setPopUp('submitted')
                    // console.log(response);
                    // setTimeout(setPopUp(false), 20000)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed')
            }

        }


    };
    const tripTypes = ["One-Way", "Round Trip"];

    return (
        <form ref={props.close}  onSubmit={(e)=>handleSubmit(e)} className='blurred-div'>
            <div className="blueHeader">
                Personal Information

            </div>
            <div className="IELTS1">

                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='Surname_AP'
                                placeholder='Surname as on intl. passport' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Full Name' star='*' type='text'
                                localStorageName='FullName_AP'
                                placeholder='Full name as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Email' star='*' type='email'
                                localStorageName='Email_AP'
                                placeholder='jarom@gmail.com' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Phone Number' star='*' type='number'
                                localStorageName='Phone_number_AP'
                                placeholder='Phone Number' required={true}>

                </VisaInputField>

            </div>

            <div className="blueHeader">
                Trip Information
            </div>
            <div className="IELTS1">

                <div className='visa_inputField ' id='inputTop'>
                    <label>
                        Type of Trip:<span>*</span>
                    </label>
                    <TitleDropdown name='Type of Trip' localStorageName='Type_of_Trip_AP' mwidth='130px'
                                   mxfont='14px' mxwidth='100%' mfont='10px'
                                   options={tripTypes} title="Select"
                                   className='visa_input'/>

                </div>
                <div className="IELTSInner2">

                    <VisaInputField labelName='Name of Airport' star='*' type='text'
                                    localStorageName='airPort_AP'
                                    placeholder='Los Angeles International Airport (LAX)' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Terminal of the Airport' star='*' type='text'
                                    localStorageName='Airport_terminal_AP'
                                    placeholder="Terminal 4" required={true}>

                    </VisaInputField>
                </div>
                <div className="IELTSInner2">

                    <VisaInputField labelName='Pickup Date' star='*' type='date'
                                    localStorageName='Pickup_Date_AP'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Pickup Time' star='*' type='text'
                                    localStorageName='PickupTime_AP'
                                    placeholder='4:00PM (G.M.T)' required={true}>

                    </VisaInputField>
                </div>

                <div className="IELTSInner2">
                    <VisaInputField labelName='Airline' star='*' type='text'
                                    localStorageName='AirLine_AP'
                                    placeholder='Delta Airline' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Flight Number' star='*' type='text'
                                    localStorageName='FLightNumber_AP'
                                    placeholder="DL1234" required={true}>

                    </VisaInputField>
                </div>


                <div className="IELTSInner2">
                    <VisaInputField labelName='Number of Luggage' star='*' type='text'
                                    localStorageName='number_of_Luggage_AP'
                                    placeholder='2' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Number of Passengers ' star='*' type='text'
                                    localStorageName='Passengers_AP'
                                    placeholder='1' required={true}>

                    </VisaInputField>
                </div>
                <div className="IELTSInner2">

                    <VisaInputField labelName='Pickup Location' star='*' type='text'
                                    localStorageName='PickupLocation_AP'
                                    placeholder='123 Main Street, New York, NY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Drop-Off Location' star='*' type='text'
                                    localStorageName='Drop_Off_Location_AP'
                                    placeholder="456 Broadway, New York, NY" required={true}>

                    </VisaInputField>
                </div>

                <br/>
                <div className="subBlueHeader">
                    Special Requests
                </div>



                <VisaInputField labelName='Comments/Special Requests (Optional)' star='*' type='text'
                                localStorageName='SpecialRequest_AP'
                                placeholder=' Please provide a child seat and assistance with luggage.' >

                </VisaInputField>







            </div>


            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveJetHireForm(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>

    );
};

export default AirportPickupForm;