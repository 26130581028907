import React, {useEffect, useRef, useState} from 'react';
import './search.css';
import Substitute from "../Home/substitute";
import Filter from "./filter";
import axios from "axios";
import TicketCard from "./ticket";
import ReloadContext from "../Context/Reload";
import {decrypt} from "../Security";
import data from "./data";
import TicketCardSkeleton from '../SkeletonLoadingComponent/FlightSearchPageSkeleton/TicketCardSkeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import envVariables from "../../dev";
import SearchState from "./searchStates";
import {Helmet} from "react-helmet-async";

export function convertTime (timestamp){
    const date = new Date(timestamp * 1000); // Convert the Unix timestamp to milliseconds
    const formattedTime = date.toISOString().substr(11, 5); // Extract the time portion in the format "HH:mm"
    return formattedTime;
};
const getMonthName = (month) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[month];
};

export function calculateDay (timestamp) {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const date = new Date(timestamp * 1000);
    const day = date.getUTCDay();
    const month = date.getUTCMonth();
    const dayOfMonth = date.getUTCDate();

    const formattedDay = `${weekdays[day]} ${dayOfMonth} ${getMonthName(month)}`;

    return formattedDay;
};
export function getAirlineImageUrl(airlineCode) {
    return `https://images.kiwi.com/airlines/64x64/${airlineCode}.png?default=airline.png`;
}


function SearchResult() {
    let leavingDate;
    let returningDate;
    let adult;
    let infant;
    let children;
    let Ticket;
    let Trip;
    let factor;
    let Cabbin;
    let Checked;
    let currency;
    let uniqueAirlines;
    const [reloadFlag, setReloadFlag] = useState(false);
    const [sort, setSort] = useState('');
    const [leavingDateMod, setleavingDateMod] = useState('');
    const [returningDateMod, setreturningDateMod] = useState('');
    const [fromCountry, setfromCountry] = useState(['LOS','Lagos']);
    const [toCountry, settoCountry] = useState(['LOS','Lagos']);
    const [airNames, setairNames] = useState([]);
    const [showFirst, setShowFirst] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [showTicket, setShowTicket] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showAvailable, setShowAvailable] = useState(false);
    const [showSecond, setShowSecond] = useState(false);
    const [ticketInfo, setticketInfo] = useState(['LOS','Lagos']);
    const [ticketInfoFiltered, setticketInfoFiltered] = useState(['LOS','Lagos']);
    const [displayedTickets, setDisplayedTickets] = useState(20);
    const [loading, setLoading] = useState(()=> true)







    const allocateBaggage = (numBaggage, numChildren, numAdults) => {

        const adultsBaggage = [];
        const childrenBaggage = [];
        // Allocate baggage to adults
        if(numAdults > 0){
            for (let i = 0; i < numAdults; i++) {
                if (numBaggage > 0) {
                    adultsBaggage.push(1);
                    numBaggage--;
                } else {
                    adultsBaggage.push(0);
                }
            }
            for (let i = adultsBaggage.length; i < numAdults; i++) {
                adultsBaggage.push(0);
            }
            // console.log("realAdult",adultsBaggage)
        }


        // Allocate baggage to children
        if(numChildren > 0){
            for (let i = 0; i < numChildren; i++) {
                if (numBaggage > 0) {
                    childrenBaggage.push(1);
                    numBaggage--;
                } else {
                    childrenBaggage.push(0);
                }
            }
            for (let i = childrenBaggage.length; i < numChildren; i++) {
                childrenBaggage.push(0);
            }
        }


        return {
            adultsBaggage: adultsBaggage.join(","),
            childrenBaggage: childrenBaggage.join(","),
        };
    };

    const distributeBaggage= (baggage, numAdults, numChildren) => {
        const adultBags = [];
        const childBags = [];

        while (baggage > 0) {
            for (let i = 0; i < numAdults; i++) {
                if (baggage > 0) {
                    adultBags[i] = (adultBags[i] || 0) + 1;
                    baggage--;
                } else {
                    break;
                }
            }

            for (let i = 0; i < numChildren; i++) {
                if (baggage > 0) {
                    childBags[i] = (childBags[i] || 0) + 1;
                    baggage--;
                } else {
                    break;
                }
            }
        }

        for (let i = adultBags.length; i < numAdults; i++) {
            adultBags.push(0);
        }
        for (let i = childBags.length; i < numChildren; i++) {
            childBags.push(0);
        }

        const adultResult = adultBags.join(",");
        const childResult = childBags.join(",");

        return {
            adultResult,
            childResult,
        };
    }
    const ticketType = () => {
        const ticket = localStorage.getItem('Ticket');
        switch (ticket) {
            case 'Economy':
                return 'M';
            case 'Premium Economy':
                return 'W';
            case 'Business':
                return 'C';
            case 'First Class':
                return 'F';
            default:
                return '';
        }
    };

    const handleSubmit = () => {
        setLoading(true)
        localStorage.setItem('listKey', JSON.stringify([]));
        setfromCountry(JSON.parse(localStorage.getItem('fromCountry0')));
        settoCountry(JSON.parse(localStorage.getItem('toCountry0')));
        leavingDate= new Date (localStorage.getItem('leavingDate0'));
        returningDate = new Date (localStorage.getItem('returningDate0'));
        setleavingDateMod(leavingDate.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        }));
        setreturningDateMod(returningDate.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        }));
        adult=localStorage.getItem('adults');
        children=localStorage.getItem('children');
        infant=localStorage.getItem('infant');
        factor = adult + children + infant;
        Ticket=localStorage.getItem('Ticket');
        Trip=localStorage.getItem('Trip');
        Cabbin = decrypt(localStorage.getItem('CabinBaggage') ? localStorage.getItem('CabinBaggage') : '' );
        Checked = decrypt(localStorage.getItem('CheckedBaggage') ? localStorage.getItem('CheckedBaggage') : '');
        const { adultResult, childResult } = distributeBaggage(Checked, adult, children);
        const { adultsBaggage, childrenBaggage } = allocateBaggage(Cabbin,children, adult );
        currency= localStorage.getItem('Currency')
        const fromCountry_=JSON.parse(localStorage.getItem('fromCountry0'));
        const toCountry_=JSON.parse(localStorage.getItem('toCountry0'));
        const actualStop =JSON.parse(localStorage.getItem('stopOver')|| '[2,25]');
        const leaveDate =  localStorage.getItem("leavingDate0");
        const sort_ = localStorage.getItem('sort')
        const retDate =  localStorage.getItem("returningDate0");
        const price =  localStorage.getItem("price");
        const maxtime =  localStorage.getItem("maxTravelTime");
        const stopOver =  localStorage.getItem("checkedKey") || 'anyStop';
        const stopoverFrom = `${actualStop[0]}:00`;
        const stopoverTo = `${actualStop[1]}:00`;
        let departureFrom = localStorage.getItem("dep_from");
        let departureTo = localStorage.getItem("dep_to");
        let arrivalFrom = localStorage.getItem("arr_from");
        let arrivalTo = localStorage.getItem("arr_to");
        let retFrom = localStorage.getItem("retFrom0");
        let retTo = localStorage.getItem("retTo0");
        let searchLink = 'search';
        const getAPIKEY =()=>{
            const trip = localStorage.getItem('Trip')||'One Way'
            let apikey
            if (trip === 'One Way'|| trip === 'Round Trip' ){
                apikey = envVariables.APIKEY_RETURN_ONEWAY;
            }
            else{
                apikey = envVariables.APIKEY_MULTICITY;
            }
            return apikey
        }

        if(Trip === 'One Way'){
            retTo ='';
            retFrom = '';
            arrivalFrom = '';
            arrivalTo = '';
            departureFrom = '';
            departureTo = '';
        }

        let stop = 0;
        if(stopOver === 'anyStop'){
            stop = null;
        }
        else if (stopOver === 'directStop'){
            stop = 0;
        }
        else if (stopOver === 'upToOneStop'){
            stop = 1;
        }
        else if (stopOver === 'upToTwoStop'){
            stop = 2;
        }
        let params ={
            fly_from: fromCountry_[0] ,
            fly_to: toCountry_[0],
            date_from: leaveDate,
            date_to: retDate,
            return_from: retFrom,
            return_to: retTo,
            adults: adult,
            children: children,
            infants: infant,
            sort: sort_,
            curr: currency,
            price_to: price,
            selected_cabins: ticketType(),
            max_stopovers: stop,
            adult_hold_bag: adultResult,
            adult_hand_bag: adultsBaggage,
            child_hold_bag: childResult,
            child_hand_bag: childrenBaggage,
            max_fly_duration: maxtime,
            stopover_from: stopoverFrom,
            stopover_to: stopoverTo,
            ret_dtime_from: departureFrom,
            ret_dtime_to: departureTo,
            ret_atime_from: arrivalFrom,
            ret_atime_to: arrivalTo,
        }
        const numberOfDictionaries = localStorage.getItem('Multi-City');
        if(Trip === 'Multi-City'){
            searchLink= 'flights_multi'
            const dictionaryArray = [];
            for (let i = 0; i < numberOfDictionaries; i++) {
                const from = JSON.parse(localStorage.getItem(`fromCountry${i}`));
                const to = JSON.parse(localStorage.getItem(`toCountry${i}`));
                const dictionary = {
                    fly_from: from[0],
                    fly_to: to[0],
                    date_from: localStorage.getItem(`leavingDate${i}`),
                    date_to: localStorage.getItem(`returningDate${i}`),
                    adults: adult,
                    children: children,
                    infants: infant,
                    sort: sort_,
                    curr: currency,
                    price_to: price,
                    max_stopovers: stop,
                    adult_hold_bag: adultResult,
                    adult_hand_bag: adultsBaggage,
                    child_hold_bag: childResult,
                    child_hand_bag: childrenBaggage,
                    max_fly_duration: maxtime,
                    stopover_from: stopoverFrom,
                    stopover_to: stopoverTo,
                    ret_dtime_from: departureFrom,
                    ret_dtime_to: departureTo,
                    ret_atime_from: arrivalFrom,
                    ret_atime_to: arrivalTo,
                };
                dictionaryArray.push(dictionary);
            }
            params = dictionaryArray;
            axios
                .post(`https://api.tequila.kiwi.com/${searchLink}`, {
                    requests: params},{
                    headers: {
                        "accept": "application/json",
                        "apikey": getAPIKEY(),
                        'Content-Type': 'application/json',
                    }
                })

                .then((response) => {

                    if (response.status === 200 || response.status === 201) {
                        setShowTicket(true);
                        setShowLoading(false);
                        // console.log('test',response.data[0].route[response.data[0].route.length -1].cityCodeTo);
                    } else {
                        setShowTicket(false);
                        setShowLoading(true);
                        // console.error("Error: Unexpected response code", response.status);
                    }

                    const airlines = response.data.data.reduce((acc, curr) => acc.concat(curr.airlines), []);
                    uniqueAirlines = [...new Set(airlines)];
                    const ticketData = response.data.map((data) => {
                        const departureTime = convertTime(data.dTime);
                        const arrivalTime = convertTime(data.aTime);
                        const day = calculateDay(data.dTimeUTC);
                        const day2 = calculateDay(data.aTimeUTC);
                        const route = data.route.length - 1;
                        const airlines = data.airlines;
                        const airlineImages = airlines.map((airline) => getAirlineImageUrl(airline));
                        return {
                            fromCode: response.data[0].route[0].route[0].flyFrom,
                            toCode: data.flyTo,
                            departure: departureTime,
                            arrival: arrivalTime,
                            id: data.id,
                            price: data.price,
                            duration: data.fly_duration,
                            day: day,
                            day2: day2,
                            route: route,
                            cityFrom: data.cityFrom,
                            cityTo: data.cityTo,
                            airLinesLogo: airlineImages,
                            moreInfo: data.route,
                            airLines: data.airlines,
                        };
                    });
                     setticketInfo(ticketData);
                    setticketInfoFiltered(ticketData)
                    if (Array.isArray(ticketData) && ticketData.length === 0) {
                        setShowAvailable(true);
                        setairNames([]);
                    } else {
                        setShowAvailable(false);
                        if (uniqueAirlines.length !== 0) {
                            setairNames(getNamesFromIds());
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error fetching countries:", error);
                });
        }
        else {
            axios
                .get(`https://api.tequila.kiwi.com/${searchLink}`, {
                    params: params,
                    headers: {
                        "accept": "application/json",
                        "apikey": getAPIKEY()
                    }
                })
                .then((response) => {

                    if (response.status === 200|| response.status ===201) {
                        setShowTicket(true);
                        setShowLoading(false);
                        setLoading(false);
                        localStorage.setItem('rate', parseInt(response.data.fx_rate) )
                        const airlines = response.data.data.reduce((acc, curr) => acc.concat(curr.airlines), []);
                        uniqueAirlines = [...new Set(airlines)];

                        const ticketData = response.data.data.map((data) => {
                            const departureTime = convertTime(data.dTime);
                            const arrivalTime = convertTime(data.aTime);
                            const day = calculateDay(data.dTimeUTC);
                            const day2 = calculateDay(data.aTimeUTC);
                            const route = data.route.length - 1;
                            const airlines = data.airlines;
                            const airlineImages = airlines.map((airline) => getAirlineImageUrl(airline));
                            const bookingToken = data.booking_token;

                            return {
                                fromCode: data.flyFrom,
                                toCode: data.flyTo,
                                departure: departureTime,
                                arrival: arrivalTime,
                                id: data.id,
                                price: data.price,
                                duration: data.fly_duration,
                                day: day,
                                day2: day2,
                                route: route,
                                cityFrom: data.cityFrom,
                                cityTo: data.cityTo,
                                airLinesLogo: airlineImages,
                                moreInfo: data.route,
                                airLines: data.airlines,
                                bookingToken: bookingToken,
                            };
                        });
                        setticketInfo(ticketData);
                        setticketInfoFiltered(ticketData)
                        if (Array.isArray(ticketData) && ticketData.length === 0) {
                            setShowAvailable(true);
                            setairNames([]);
                        } else {
                            setShowAvailable(false);
                            if (uniqueAirlines.length !== 0) {
                                setairNames(getNamesFromIds());
                            }
                        }
                    } else {
                        setShowTicket(false);
                        setShowLoading(true);
                        setLoading(true)
                        // console.error("Error: Unexpected response code", response.status);
                    }

                })
                .catch((error) => {
                    console.error("Error fetching countries:", error);
                })
                .finally(()=>{

                 });
        }
    };
    const getNamesFromIds = () => {
        return uniqueAirlines.map(id => {
            const item = data.find(item => item.id === id);
            return item ? { id: item.id, name: item.name } : { id, name: '' };
        });
    };


    const loadMoreTickets = () => {
        setDisplayedTickets(displayedTickets + 6); // Increase the number of displayed tickets by 6
    };

    const [showDiv, setShowDiv] = useState(false);
    const divRef = useRef(null);
    const divRef2 = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setShowDiv(!showDiv);
                divRef2.current.click();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showDiv]);

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const handleClickFirst = () => {
        scrollToTop();
        setShowFirst(false);
        setShowSecond(true);
        setShowDiv(!showDiv);
    };
    const handleFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleDivClick = () => {
        if(showDiv===false){
            setShowDiv(true);
        }
        setShowFirst(true);
        setShowSecond(false);
        // console.log("testing", !showDiv)
    };
    useEffect(() => {
        handleSubmit();
        if(localStorage.getItem('sort') === 'price'){
            handleClick1()
        }
        else if(localStorage.getItem('sort') === 'quality'){
            handleClick()
        }
        else if(localStorage.getItem('sort') === 'duration'){
            handleClick2()
        }
        else if(localStorage.getItem('sort') === 'date'){
            handleClick3()
        }
    }, []);

    const [isClicked, setIsClicked] = useState(true);
    const [isClicked1, setIsClicked1] = useState(false);
    const [isClicked2, setIsClicked2] = useState(false);
    const [isClicked3, setIsClicked3] = useState(false);
    const handleClick = () => {
        setIsClicked(true);
        setIsClicked1(false);
        setIsClicked2(false);
        setIsClicked3(false);
        localStorage.setItem('sort','quality');
        handleSubmit();

    };
    const handleClick1 = () => {
        setIsClicked(false);
        setIsClicked1(true);
        setIsClicked2(false);
        setIsClicked3(false);
        localStorage.setItem('sort','price')
        handleSubmit();
    };
    const handleClick2 = () => {
        setIsClicked(false);
        setIsClicked1(false);
        setIsClicked2(true);
        setIsClicked3(false);
        localStorage.setItem('sort','duration')
        handleSubmit();
    };
    const handleClick3 = () => {
        setIsClicked(false);
        setIsClicked1(false);
        setIsClicked2(false);
        setIsClicked3(true);
        localStorage.setItem('sort','date')
        handleSubmit();

    };
    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia('(min-width: 760px)').matches) {
                setShowFilter(true);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const flightLocation = ticketInfo[0]?.cityFrom ? `${ticketInfo[0].cityFrom} to ${ticketInfo[0].cityTo}` : <Skeleton width={175}/> ;
    const flightLocationtoLS = ticketInfo[0]?.cityFrom ? `${ticketInfo[0].cityFrom} - ${ticketInfo[0].cityTo}` : '' ;
    localStorage.setItem('flightLocationtoLS', flightLocationtoLS)

    adult=parseInt(localStorage.getItem('adults'));
    children=parseInt(localStorage.getItem('children'));
    infant=parseInt(localStorage.getItem('infant'));
    factor = adult + children + infant;

    const filterFlightTicketByAirlines = () =>{
        const babble = JSON.parse(localStorage.getItem("listKey"));
        const filteredData = babble.length === 0
            ? ticketInfo
            : ticketInfo.filter(item => {
                const airLines = item.airLines;
                return airLines.some(airLine => babble.includes(airLine));
            });
        setticketInfoFiltered(filteredData)
    }



    return (
        <ReloadContext.Provider value={{ reloadFlag, setReloadFlag }}>
            <Helmet>
                <title>{ticketInfo[0]?.cityFrom ? `${ticketInfo[0].cityFrom} - ${ticketInfo[0].cityTo}`: 'Flight Search'} |  Jarom Travel Agency</title>
                <meta name="description" content="Search for flights with our travel agency. Find the best deals on flights to your favorite destinations." />
                <meta name="keywords" content="flight search, book flights, cheap flights, travel agency" />
                <link rel="canonical" href="https://jaromtravels.com/SearchResult" />
                <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
                </script>
            </Helmet>
            <div>
            <div className="searchHeader">
                <div className="searchDetails">
                    <div className='searchAirports'>
                        <div id='alignLeft'>
                            {fromCountry[1]}
                        </div>
                        <div id='alignRight'>
                            - &nbsp;&nbsp;&nbsp;&nbsp;{toCountry[1]}
                        </div>
                    </div>
                    <div className="otherDetails">
                        <span>{leavingDateMod}<br/>{returningDateMod} </span>&nbsp;&nbsp;&nbsp;&nbsp;{adult} Adult, {Ticket}, {Trip}<br/>&nbsp;&nbsp;&nbsp;&nbsp;{children} Children,{infant} Infant
                    </div>
                </div>
                {showFirst && (
                <button onClick={handleClickFirst}>
                    Modify
                </button>
                )}
                {showSecond && (
                    <button ref={divRef2} onClick={handleDivClick}>
                        Modify
                    </button>
                )}
            </div>
            {showDiv &&
            <div className='Modify' >
                <div className='modifySave'>
                    <div className='modifyToggle'>
                        <div className='fir' ref={divRef}>
                            <Substitute isFlight={true} handleSubmit={handleSubmit} className='sub-fix'/>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className='agbaContainer'>
                <div className='filterSeparator'>
                    {showFilter &&
                        <div className='filterCover'>
                            <h1 className='availableFlight'>
                                Available Flight
                            </h1>
                            <div>
                                <Filter handleSubmit={handleSubmit} airlines={airNames} checkloading={showLoading} isavaliLoad={showAvailable} filterAirlines={filterFlightTicketByAirlines} factor={factor}/>
                            </div>
                        </div>
                    }
                    <div className='myWidth' >
                        <div className='flightLocation'>Flights from <span> { flightLocation }</span></div>
                        <div className='flightHeaders'>
                        <div className='item' id='flightFilter' onClick={handleFilter}>
                            Filter
                        </div>
                        <div className={isClicked ? 'flightOutline' : 'item'}
                             onClick={handleClick}>
                            Best
                        </div>
                        <div className={isClicked1 ? 'flightOutline' : 'item'}
                             onClick={handleClick1}>
                            Cheapest
                        </div>
                        <div className={isClicked2 ? 'flightOutline' : 'item'}
                             onClick={handleClick2}>
                            Fastest
                        </div>
                        <div className={isClicked3 ? 'flightOutline' : 'item'}
                             onClick={handleClick3}>
                            Recommended<br/> <span>Earliest departure</span>
                        </div>
                    </div>

                        {(showLoading || loading) && (
                            <div>
                                <TicketCardSkeleton cardNum={10}/>
                            </div>
                        )}


                        {(showTicket && !showLoading && !loading )&& (
                            <div >
                                {ticketInfoFiltered.slice(0, displayedTickets).map((ticket) => (
                                    <div key={ticket.id}>
                                        <TicketCard keyId={ticket.id} stops={ticket.route} airlines={ticket.airLinesLogo} day2={ticket.day2} day={ticket.day} price={ticket.price} route={ticket.moreInfo} duration={ticket.duration} departure={ticket.departure} arrival={ticket.arrival} fromCountry={fromCountry[1]} toCountry={toCountry[1]} fromCode={ticket.fromCode} toCode={ticket.toCode} token ={ticket.bookingToken} />

                                    </div>
                                ))}
                                {displayedTickets < ticketInfoFiltered.length && (
                                    <div className='ticketButtonCase'>
                                        <button onClick={loadMoreTickets} className='ticketLoad'>Load more</button>
                                    </div>
                                )}
                            </div>
                            )}

                        {showAvailable && (
                            <>
                                <SearchState
                                    img='https://res.cloudinary.com/do5wu6ikf/image/upload/v1688481050/starpenzu/Group_133545_gbym6p.svg'
                                    BtnText='Modify Search'
                                    handleClickFirst={handleClickFirst}
                                    HeadText='Oops! we couldn’t find your trip, try to modify your search'
                                />

                                {/*<SearchState*/}
                                {/*    img='https://res.cloudinary.com/do5wu6ikf/image/upload/v1713379712/starpenzu/flight-booking_bro_xvp4ne.svg'*/}
                                {/*    BtnText='Go back to search page'*/}
                                {/*    handleClickFirst={handleClickFirst}*/}
                                {/*    HeadText='Flight no longer available!'*/}
                                {/*    SubText='Refresh your search to get the latest pricing and availability'*/}
                                {/*/>*/}

                                {/*<SearchState*/}
                                {/*    img='https://res.cloudinary.com/do5wu6ikf/image/upload/v1713379712/starpenzu/coins_amico_wgcg4p.svg'*/}
                                {/*    BtnText='Go back to search page'*/}
                                {/*    handleClickFirst={handleClickFirst}*/}
                                {/*    HeadText='Get fresh prices'*/}
                                {/*    SubText='Refresh your search to get the latest pricing and availability'*/}
                                {/*/>*/}
                            </>
                        )}
                    </div>
                </div>


            </div>

            {/*<MyFooter/>*/}
        </div>
        </ReloadContext.Provider>
    );
}

export default SearchResult;
