import React, {useState} from "react";
import SupportMain from "../Support/SupportMain";
import SupportUsForm from "./SupportUsForm";
import "./SupportPage.css"
import {Helmet} from "react-helmet-async";


export default function SupportPage() {
    const [loading, setLoading]= useState(false)
    //
    // useEffect(() => {
    //     // Use setTimeout to change the message after 2 seconds
    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //     }, 2000);
    //
    //     // Cleanup the timer to avoid memory leaks
    //     return () => clearTimeout(timer);
    // }, []);
    return(
        <>
            <Helmet>
                <title>Support Page - Jarom Travel Agency</title>
                <meta name="description" content="Need assistance? Contact our support team for help with your travel bookings and inquiries." />
                <meta name="keywords" content="support, customer service, travel support, travel inquiries" />
                <link rel="canonical" href="https://jaromtravels.com/Support" />
                <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
                </script>
            </Helmet>
            <div>

                <div className='supportPageBody'>

                    <div  className="hero-container">
                            <span className='decoy'>
                                <div className="hero-text">
                                <h1>How can we help?</h1>
                                <p>We are always here for you.</p>
                            </div>
                            </span>

                    </div>

                    <SupportMain loading={loading}/>

                    <SupportUsForm loading={loading}/>
                </div>
            </div>
        </>
    )
}

