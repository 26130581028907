import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/Home/Home";
import SignUpOne from "./components/SignUp/SignUpOne";
import React, {useRef, useState} from "react";
import SignUpTwo from "./components/SignUp/SignUpTwo";
import Login from "./components/Login/Login";
import PasswordReset from "./components/PasswordPages/PasswordReset";
import PasswordResetEmail from "./components/PasswordPages/PasswordResetEmail";
import Visa from "./components/Visa/visa";
import AboutUs from "./components/AboutUs/AboutUs";
import SupportPage from "./components/Support/SupportPage";
import Header from "./components/navbar";
import SearchResult from "./components/FlightSearch/search";
import ManageBookingPage from "./components/ManageBookingPage/ManageBookingPage";
import BookingPage from "./components/Bookingpage/BookingPage";
import FlightSeatArrg from "./components/FlightSeatBooking/FlightSeatArrg";
import Testcomponent from "./components/myFooter/Testcomponent";
import OurServices from "./components/OurServices/Ourservices"
import MyFooter from "./components/myFooter/MyFooter";
import ResetPassWordConfirmation from "./components/PasswordPages/ResetPasswordConfirmation";
import ErrorPage from "./components/Home/404Page";
import CookiesUI from "./components/Cookies/CookiesUI";


function App() {
    // let navigate = useNavigate();
    const targetDivRef = useRef(null);
    const [isVisible, setIsVisible] =   useState(() => {
        const storedValue = localStorage.getItem('isVisible');
        return storedValue ? JSON.parse(storedValue) : true;
    });

    if(isVisible){
        window.scrollTo({top: 0, behavior: 'smooth'});
        document.body.style.overflow = 'hidden';
    }


    const handleClose = (cookies) => {
        setIsVisible(false);
        document.body.style.overflow = 'auto';
        localStorage.setItem('Cookies', cookies)
    };
    localStorage.setItem('isVisible', isVisible)

    const cookiesState = localStorage.getItem('Cookies');

    function clearLocalStorage() {
        const itemsToKeep =
            [
                'fromCountry0',
                'toCountry0',
                'leavingDate0',
                'returningDate0',
                'recentlySelectedCountries',
                'Trip',
                'retTo0',
                'retFrom0',
                'adults',
                'infant',
                'children',
                'Currency',
                'CheckedBaggage',
                // 'isVisible',
                // 'Cookies'
            ];

        for (let key in localStorage) {
            if (!itemsToKeep.includes(key)) {
                localStorage.removeItem(key);
            }
        }
    }
    function clearAllCookies() {
        let cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    }

    window.addEventListener('beforeunload', function (event) {
        if (event.currentTarget.performance.navigation.type !== 1) { // 1 means page is reloaded
            alert('Before unload event triggered\n' +
                'Current cookiesState: ' + cookiesState + '\n' +
                'Performance navigation type: ' + event.currentTarget.performance.navigation.type);

            if (cookiesState === 'Manage') {
                clearLocalStorage();
                sessionStorage.clear();
                clearAllCookies();
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }
    });


    return (
      <div className={`fullOver ${isVisible ? 'withOverlay' : 'NOOverlay'}`}>
          <Router>
              <div className="cook">
                  <CookiesUI cookiesState={cookiesState} isVisible={isVisible} handleClose={handleClose} />
              </div>
              <Header get={targetDivRef}/>
              <Routes>
                  <Route exact  path ="/" element={
                      <Home targ={targetDivRef}/>
                  } />
                  <Route path ="/signup" element={<SignUpOne/>}/>
                  <Route path ="/signup/step" element={<SignUpTwo/>}/>
                  <Route path ="/login" element={<Login/>}/>
                  <Route path ="/password-reset-email" element={<PasswordResetEmail/>}/>
                  <Route path ="/password-reset/:uidb64/:token" element={<PasswordReset/>}/>
                  <Route path ="/visa" element={<Visa/>}/>
                  <Route path ="/AboutUs" element={<AboutUs/>}/>
                  <Route path ="/Support" element={<SupportPage/>}/>
                  <Route path ="/SearchResult" element={<SearchResult/>}/>
                  <Route path ="/managebookingpage" element={<ManageBookingPage/>}/>
                  <Route path ="/bookingpage" element={<BookingPage/>}/>
                  <Route path ="/plane" element={<FlightSeatArrg/>}/>
                  <Route path ="/testcomponent" element={<Testcomponent/>}/> {/*my playground*/}
                  <Route path ="/ourservices" element={<OurServices/>}/>
                  <Route path ="/password-reset-email-confirmation" element={<ResetPassWordConfirmation/>}/>
                  <Route path='*' element={<ErrorPage/>}/>
              </Routes>
              <MyFooter/>
          </Router>
      </div>

  );
}


export default App;



